import React, { useEffect, useState } from 'react';
import './App.css';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from "firebase/auth";
import { Helmet } from "react-helmet";
import { stringify } from '@firebase/util';

const DB_ENDPOINT = 'https://du653krpylybzweht7fpyze7ea0vqyea.lambda-url.ap-northeast-2.on.aws/'

function HistoryInfo({
  history
}) {

  return (
    <div className='mt-6 flex flex-col items-center '>
      {history.tasks.map((value, index) => (
        <p className='mt-4 outline-double p-2' key={index}>
          {value.url} | {value.playtime} | {value.origin_language} => {value.target_language} | {String(value.is_done)}
        </p>
      ))}

    </div>
  );
}

function YoutubeInfo({
  task
}) {

  return (
    <div className='mt-6 flex flex-row border-solid border-2 border-indigo-600 '>
      <img className='py-5 px-2 ' src={task.url} />
      <div className='py-5'>
        <div className='text-md text-indigo-600'>{task.duration}</div>
        <div className='text-md'>{task.title}</div>
      </div>
    </div>
  );
}


function App() {
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [comp_email, setComp_email] = useState('');
  const [currentUser, setCurrentUser] = useState();
  const [task, setTask] = useState();
  const [task_id, setTask_id] = useState();
  const [uid, setUid] = useState();
  const [orilang, setOriLang] = useState('auto');
  const [tarlang, setTarLang] = useState();
  const [history, setHistory] = useState([]);




  function handleButtonLogin(event) {

    const provider = new GoogleAuthProvider();

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        // console.log(user)
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });

  }
  async function handleButtonOnClick1(event) {
    if (!url || !url.includes('http')) {
      window.alert('url을입력해주세요');
      return;
    }

    const q = new URL(url);
    if (!q.searchParams.get('v')) {
      window.alert('올바른 링크를 입력해주세요');
      return;
    }
    // console.log(q.searchParams.get('v'));
    const result = await fetch(`https://content-youtube.googleapis.com/youtube/v3/videos?part=snippet&part=contentDetails&id=${q.searchParams.get('v')}&key=AIzaSyBQJzJbok7qR1EM-7O3pSDOgt3UK8wVfbg`);
    const data = await result.json();


    if (data.items.length == 0) {
      window.alert('영상이 존재하지 않습니다.');
      return;
    }
    setTask({
      duration: data.items[0].contentDetails.duration,
      title: data.items[0].snippet.title,
      url: data.items[0].snippet.thumbnails.default.url,
    })
  }
  function handleButtonOnClick2(event) {
    setComp_email(email);
  }
  async function handleButtonOnClick3(event) {
    // alert('url:'+url+'email:'+email)

    const result = await fetch("https://beam.slai.io/1oyxb", {
      "method": "POST",
      "headers": {
        "Accept": "*/*",
        "Accept-Encoding": "gzip, deflate",
        "Authorization": `${process.env.REACT_APP_SERVER_API_KEY}`,
        "Content-Type": "application/json"
      },
      "body": JSON.stringify({ "video_url": url })
    });
    const data = await result.json();
    // TODO : 성공하면 모달창 띄워서 이메일 발송 예정 메세지 띄우기
    // console.log(data);
    setTask_id(data.task_id);
    const result2 = await fetch(DB_ENDPOINT + 'create-task', {
      "method": "PUT",
      "headers": {
        "Accept": "*/*",
        "Accept-Encoding": "gzip, deflate",
        "Content-Type": "application/json"
      },
      "body": JSON.stringify({ "user_id": uid, "is_done": false, "email": email, "task_id": data.task_id, "url": url, "origin_language": orilang, "target_language": tarlang, "playtime": task.duration })
    });
    const data2 = await result2.json();
  }
  function handleInputChange1(event) {
    setUrl(event.target.value);
  }
  function handleInputChange2(event) {
    setEmail(event.target.value);
  }
  function handleButtonLogout(event) {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      setCurrentUser();
    }).catch((error) => {
      // An error happened.
    });
  }
  function handleSelectChange1(event) {
    setOriLang(event.target.value);
  }
  function handleSelectChange2(event) {
    setTarLang(event.target.value);
  }
  useEffect(() => {
    //현재 로그인 여부
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        const displayName = user.displayName;
        const email = user.email;
        const photoURL = user.photoURL;
        const emailVerified = user.emailVerified;
        // console.log(uid, displayName, email, photoURL, emailVerified);
        setUid(uid);
        setCurrentUser(user);
        // ...
      } else {
        // make all the states null
        setUid(null);
        setCurrentUser(null);
        setTask(null);
        setTask_id(null);
        setUrl(null);
        setEmail(null);
        setComp_email(null);
        setOriLang(null);
        setTarLang(null);
        setHistory([]);
        // User is signed out
      }
    });
  }, []);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //fetch data from db
        const result = fetch(DB_ENDPOINT + 'list-tasks/' + user.uid, {
          "method": "GET",
          "headers": {
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate",
            "Content-Type": "application/json"
          },
        });
        result.then((res) => {
          return res.json();
        }).then((data) => {
          console.log(data);
          setHistory(data);
        })
      }
    });
  }, []);

  useEffect(() => {
    if (window.location.search.includes('?v=')) {
      // console.log(`https://www.youtube.com/watch${window.location.search}`)
      setUrl(`https://www.youtube.com/watch${window.location.search}`);
    }
  }, []);
  // When rendering fetch data from a url endpoint
  return (
    <>
      <Helmet>
        <title>유튜브 더빙 생성기</title>
      </Helmet>
      <div className="bg-slate-200">
        <div className='bg-indigo-500 text-white flex flex-row justify-between items-center px-2 py-2 '>
          <div>로고</div>
          {currentUser ? <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleButtonLogout}>Logout</button>
            : <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleButtonLogin}>Login</button>}
        </div>
        <div className='px-5 py-5 max-w-md mx-auto'>
          <h1 className='mt-6 text-2xl font-bold'>유튜브 더빙 생성기</h1>
          <h2 className='mt-4 text-lg text-gray-500'>OpenAi의 <span className='impact'>Whisper API</span>를 이용한 유튜브 더빙 생성기입니다.</h2>
          <img alt='bg-image' className="mt-6 rounded-2xl shadow-lg " src='https://cdn.discordapp.com/attachments/989739733993263114/1087200992573018162/speaker_with_flying_various_words_aroundbright_style_animat_0516ed10-007b-4d05-9c43-ddc16d9cdef8.png'></img>
          <div className='mt-6 flex flex-row justify-items-center items-center '>
            <h2 className='mx-3 font-bold text-xl whitespace-nowrap '>URL</h2>
            <input className="mx-2 text-l lg:text-2xl  bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='url입력' value={url} onChange={handleInputChange1}></input>
            <button className="mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleButtonOnClick1}><div className='flex flex-row whitespace-nowrap '>입력</div></button></div>
        {/* <h2>작업내역</h2> */}
        <div className='mt-6 flex flex-row justify-items-center items-center  '>
          <h2 className='mx-3 font-bold text-xl whitespace-nowrap '>EMAIL</h2>
          <input className="mx-2 text-l lg:text-2xl  bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='email 주소입력' value={email} onChange={handleInputChange2} />
          <button className=" mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleButtonOnClick2} ><div className='whitespace-nowrap' >입력</div></button>
        <div className='email_info'>
          <div>{comp_email}</div>
        </div>
        </div>
        <div>
          {task
            ? <YoutubeInfo task={task} />
            : ''}
        </div>
        <div className='mt-6 flex flex-row justify-items-center items-center '>
            <label className="mx-3 font-bold text-xl whitespace-nowrap">Lang. IN</label>
            <select onChange={handleSelectChange1} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option className='text-red-600 text-lg' value='auto' selected>Auto Detect</option>
              <option value='en'>English</option>
              <option value='ko'>Korean</option>
              <option value='zh'>Chinese</option>
              <option value='ja'>Japanese</option>
              <option value='fr'>French</option>
              <option value='es'>Spanish</option>
              <option value='de'>German</option>
              <option value='it'>Italian</option>
              <option value='ru'>Russian</option>
              <option value='pt'>Portuguese</option>
              <option value='ar'>Arabic</option>
              <option value='hi'>Hindi</option>
              <option value='bn'>Bengali</option>
              <option value='pa'>Punjabi</option>
              <option value='ta'>Tamil</option>
              <option value='te'>Telugu</option>
              <option value='ml'>Malayalam</option>
              <option value='ur'>Urdu</option>
              <option value='vi'>Vietnamese</option>
              <option value='th'>Thai</option>
              <option value='id'>Indonesian</option>
              <option value='ms'>Malay</option>
              <option value='tr'>Turkish</option>
              <option value='pl'>Polish</option>
              <option value='nl'>Dutch</option>
              <option value='el'>Greek</option>
              <option value='hu'>Hungarian</option>
              <option value='sv'>Swedish</option>
            </select>
        </div>

        <div className='mt-6 flex flex-row justify-items-center items-center '>
          <label className="mx-3 font-bold text-xl whitespace-nowrap">Lang. OUT</label>
          <select onChange={handleSelectChange2} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option className='text-red-600 text-lg' value='en'>English</option>
            <option selected className='text-red-600 text-lg' value='ko'>Korean</option>
            <option value='zh'>Chinese</option>
            <option value='ja'>Japanese</option>
            <option value='fr'>French</option>
            <option value='es'>Spanish</option>
            <option value='de'>German</option>
            <option value='it'>Italian</option>
            <option value='ru'>Russian</option>
            <option value='pt'>Portuguese</option>
            <option value='ar'>Arabic</option>
            <option value='hi'>Hindi</option>
            <option value='bn'>Bengali</option>
            <option value='pa'>Punjabi</option>
            <option value='ta'>Tamil</option>
            <option value='te'>Telugu</option>
            <option value='ml'>Malayalam</option>
            <option value='ur'>Urdu</option>
            <option value='vi'>Vietnamese</option>
            <option value='th'>Thai</option>
            <option value='id'>Indonesian</option>
            <option value='ms'>Malay</option>
            <option value='tr'>Turkish</option>
            <option value='pl'>Polish</option>
            <option value='nl'>Dutch</option>
            <option value='el'>Greek</option>
            <option value='hu'>Hungarian</option>
            <option value='sv'>Swedish</option>
          </select>
        </div>
        
        <div>
          {orilang && tarlang
            ? <div className='text-center text-lg font-semibold text-red-400 '>
              <div >Dubbing from {orilang} to {tarlang}</div>
              <div>가격 엄청비쌈</div>
            </div>
            
            : ''}
        </div>
        
        
        <div>
          {history.length != 0 && setCurrentUser
            ? <HistoryInfo history={history} />
            : ''}
        </div>
        
        <div className='mt-6 text-center'>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleButtonOnClick3}><h2>생성요청</h2></button>
        </div>
      </div>
      </div>
      <div className='p-5 bg-indigo-600 text-white'>
        <p>This content is archived and is not being updated. For the latest documentation, see Microsoft Dynamics 365 product documentation. For the latest release plans, see Dynamics 365 and Microsoft Power Platform release plans. </p>
      </div>


    </>
  );
}

export default App;
